import React from "react"
import { useHistory } from "react-router-dom"

function Footer() {
  const history = useHistory()

  const comercialSite = () => {
    history.push("/locadora#contact")
  }

  return (
    <footer className="bg-white dark:bg-gray-900">
      <div className="container px-6 py-12 mx-auto">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">
            Sua gestão de reservas mais segura e rápida.
          </h2>

          <p className="max-w-md mx-auto mt-2 text-gray-500 dark:text-gray-400">
            Com integrações que dão mais segurança e agilidade venha conhecer
            como podemos melhorar a gestão de suas reservas.
          </p>

          <div className="flex flex-col mt-6 sm:flex-row sm:items-center sm:justify-center">
            <button
              className="w-full px-5 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md sm:mx-2 sm:order-2 sm:w-auto hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
              onClick={comercialSite}
            >
              Venha saber mais
            </button>
          </div>
        </div>

        <hr className="my-10 border-gray-200 dark:border-gray-700" />

        <div className="lg:flex">
          <div className="w-full -mx-6 lg:w-2/5">
            <div className="px-6">
              <a href="#">
                <img
                  className="h-10 hidden dark:block"
                  src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal_white.png"
                />

                <img
                  className="h-10 block dark:hidden"
                  src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal.png"
                />
              </a>

              <div className="flex mt-6 -mx-2">
                <a
                  aria-label="Instagram"
                  className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-amber-500 dark:hover:text-amber-500"
                  href="https://www.instagram.com/close_car/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    className="w-5 h-5 fill-current"
                    fill="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z" />
                  </svg>
                </a>
                <a
                  aria-label="Linkedin"
                  className="mx-2 mt-0.5 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-amber-500 dark:hover:text-amber-500"
                  href="https://www.linkedin.com/company/close-car"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    className="w-5 h-5 fill-current"
                    fill="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15 11.13v3.697h-2.143v-3.45c0-.866-.31-1.457-1.086-1.457-.592 0-.945.398-1.1.784-.056.138-.071.33-.071.522v3.601H8.456s.029-5.842 0-6.447H10.6v.913l-.014.021h.014v-.02c.285-.44.793-1.066 1.932-1.066 1.41 0 2.468.922 2.468 2.902zM6.213 5.271C5.48 5.271 5 5.753 5 6.385c0 .62.466 1.115 1.185 1.115h.014c.748 0 1.213-.496 1.213-1.115-.014-.632-.465-1.114-1.199-1.114zm-1.086 9.556h2.144V8.38H5.127v6.447z" />
                    <path d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0-2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-6 lg:mt-0 lg:flex-1">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <a
                  aria-label="Privacidade"
                  className="mr-2 text-sm font-bold dark:text-white transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300"
                  href="https://closecar-public.s3.amazonaws.com/Politica+de+Privacidade+da+CloseCar.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacidade
                </a>
                <a
                  aria-label="Privacidade"
                  className="mx-2 text-sm font-bold dark:text-white transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300"
                  href="https://closecar-public.s3.amazonaws.com/Termos+de+Uso+-+Usuario+e+CloseCar.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  Termos de uso
                </a>
              </div>
              <div className="mt-4">
                <div className="text-xs dark:text-white">
                  A Closecar faz parte da Easy APP S.A, que é uma sociedade
                  anônima registrada na República Federativa do Brasil, cuja
                  sede social está em Belo Horizonte - MG.
                </div>
                <div className="mt-2 text-xs dark:text-white">
                  © 2025 closecar.com.br todos os direitos reservados.
                  {process.env.REACT_APP_ENV === "production"
                    ? "♥"
                    : process.env.REACT_APP_ENV === "beta"
                      ? "♠"
                      : "♦"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
