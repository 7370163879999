import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

import {
  CompassOutlined,
  CheckCircleOutlined,
  StarOutlined,
} from "@ant-design/icons"
import Grid, { GridSize } from "components/atom/grid"
import Search from "components/molecule/search"
import Footer from "components/organism/footer"
import FooterRequirements from "components/organism/footer_requirements"
import Storage from "libs/storage"

import ManageDate from "@/libs/date"

import "./styles.scss"

const Landpage: React.FC<any> = () => {
  const history = useHistory()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!Storage.hasCookieAccept()) {
      setVisible(true)
    }
  }, [])

  const getInitialDates = () => {
    return {
      dropoffDate: ManageDate.nowAddDays(5, true, "YYYY-MM-DD"),
      dropoffHour: "10:00",
      pickupDate: ManageDate.nowAddDays(2, true, "YYYY-MM-DD"),
      pickupHour: "10:00",
    }
  }

  const navigateToCity = ({ pickupUcode }: { pickupUcode: string }) => {
    const dates = getInitialDates()

    const start = `${dates.pickupDate}T${dates.pickupHour}`
    const end = `${dates.dropoffDate}T${dates.dropoffHour}`

    history.push(
      `pages/vehicle/available?dropoff=${pickupUcode}&end=${end}&pickup=${pickupUcode}&start=${start}`,
    )
  }

  const onSaveCookies = (accept: boolean) => {
    Storage.cookie(accept)
    setVisible(false)
  }

  return (
    <div>
      <div>
        <Search />

        <div className="mt-8">
          <div className="text-3xl text-center">
            Estamos no Brasil todo, faça sua reserva!
          </div>

          <div className="mt-2 text-center">
            <span className="inline-block w-40 h-1 bg-amber-500 rounded-full"></span>
            <span className="inline-block w-3 h-1 ml-1 bg-amber-500 rounded-full"></span>
            <span className="inline-block w-1 h-1 ml-1 bg-amber-500 rounded-full"></span>
          </div>

          <div className="container px-6 pb-9 pt-4 mx-auto">
            <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
              <div
                className="flex max-w-md overflow-hidden bg-gray-100 rounded-lg shadow-sm cursor-pointer"
                onClick={() =>
                  navigateToCity({
                    pickupUcode: "32599fdd-7169-4fb9-bf9f-090d9123d555",
                  })
                }
              >
                <div
                  className="w-1/3 bg-cover"
                  style={{
                    backgroundImage:
                      "url('https://closecar-public.s3.us-east-1.amazonaws.com/salvador.jpg')",
                  }}
                />
                <div className="w-2/3 p-4 md:p-4">
                  <h1 className="text-xl font-bold text-gray-800">Salvador</h1>

                  <p className="mt-2 text-sm text-gray-600">
                    Cultura, praias douradas e axé se misturam na mais vibrante
                    capital do Brasil.
                  </p>
                </div>
              </div>

              <div
                className="flex max-w-md overflow-hidden bg-gray-100 rounded-lg shadow-sm cursor-pointer"
                onClick={() =>
                  navigateToCity({
                    pickupUcode: "e8209823-f7c4-42cc-bddb-802c60afb939",
                  })
                }
              >
                <div
                  className="w-1/3 bg-cover"
                  style={{
                    backgroundImage:
                      "url('https://closecar-public.s3.us-east-1.amazonaws.com/santa_catarina.jpeg')",
                  }}
                />
                <div className="w-2/3 p-4 md:p-4">
                  <h1 className="text-xl font-bold text-gray-800">
                    Navegantes
                  </h1>

                  <p className="mt-2 text-sm text-gray-600">
                    Porto dinâmico e praias tranquilas, onde o calor litorâneo
                    encontra o desenvolvimento industrial.
                  </p>
                </div>
              </div>

              <div
                className="flex max-w-md overflow-hidden bg-gray-100 rounded-lg shadow-sm cursor-pointer"
                onClick={() =>
                  navigateToCity({
                    pickupUcode: "fc6ba1d2-67be-4031-84ed-4cecd4e41ff1",
                  })
                }
              >
                <div
                  className="w-1/3 bg-cover"
                  style={{
                    backgroundImage:
                      "url('https://closecar-public.s3.us-east-1.amazonaws.com/joao_pessoa.jpg')",
                  }}
                />
                <div className="w-2/3 p-4 md:p-4">
                  <h1 className="text-xl font-bold text-gray-800">
                    João Pessoa
                  </h1>

                  <p className="mt-2 text-sm text-gray-600">
                    Piscinas naturais e pôr do sol mais oriental das Américas
                    encantam visitantes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <div className="text-center">
            <span className="block text-3xl">
              Passos simples e fáceis para alugar
            </span>
            <span className="block mt-4 text-xl">
              Aqui você vai alugar com tranquilidade e segurança.
            </span>
          </div>

          <Grid
            className="mt-8 mb-8 landing_tips_content"
            size={GridSize.Three}
          >
            <div className="landing_path">
              <CompassOutlined className="landing_path_image" />
              <div className="landing_path_title">Escolha a cidade</div>
              <div className="landing_path_subtitle">
                Busque pela cidade que você deseja.
              </div>
            </div>

            <div className="landing_path">
              <CheckCircleOutlined className="landing_path_image" />
              <div className="landing_path_title">
                Escolha o grupo de veículo
              </div>
              <div className="landing_path_subtitle">
                Temos várias marcas e modelos escolha o que se adequa a você e
                sua necessidade.
              </div>
            </div>

            <div className="landing_path">
              <StarOutlined className="landing_path_image" />
              <div className="landing_path_title">Proteções e opcionais</div>
              <div className="landing_path_subtitle">
                Tenha conveniência e flexibilidade na suas escolhas, temos
                várias opções para melhorar sua experiência.
              </div>
            </div>
          </Grid>
        </div>

        <div className="landing_divisor_content">
          <div className="landing_divisor" />
        </div>

        <FooterRequirements />
      </div>

      <Footer />

      {visible && (
        <section className="fixed max-w-2xl p-4 mx-auto bg-white border border-gray-200 md:gap-x-4 left-12 bottom-16 dark:bg-gray-900 md:flex md:items-center dark:border-gray-700 rounded-2xl">
          <div className="flex items-center gap-x-4">
            <span className="inline-flex p-2 text-blue-500 rounded-lg shrink-0 dark:bg-gray-800 bg-blue-100/80">
              <svg
                fill="none"
                height="20"
                viewBox="0 0 20 20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.9803 8.5468C17.5123 8.69458 17.0197 8.7931 16.5271 8.7931C14.2118 8.76847 12.3399 6.89655 12.3153 4.58128C12.3153 4.13793 12.3892 3.69458 12.537 3.27586C11.9951 2.68473 11.6995 1.92118 11.6995 1.13301C11.6995 0.812808 11.7488 0.492611 11.8473 0.172414C11.2315 0.0738918 10.6158 0 10 0C4.48276 0 0 4.48276 0 10C0 15.5172 4.48276 20 10 20C15.5172 20 20 15.5172 20 10C20 9.77833 20 9.55665 19.9754 9.33498C19.2611 9.26108 18.5468 8.99015 17.9803 8.5468ZM4.58128 7.31527C6.30542 7.31527 6.30542 10.0246 4.58128 10.0246C2.85714 10.0246 2.61084 7.31527 4.58128 7.31527ZM6.05912 15.7635C4.08867 15.7635 4.08867 12.8079 6.05912 12.8079C8.02956 12.8079 8.02956 15.7635 6.05912 15.7635ZM9.01478 1.33005C10.7389 1.33005 10.7389 4.28571 9.01478 4.28571C7.29064 4.28571 7.04434 1.33005 9.01478 1.33005ZM10.2463 8.84237C11.7241 8.84237 11.7241 10.8128 10.2463 10.8128C8.76848 10.8128 9.01478 8.84237 10.2463 8.84237ZM11.9704 16.9458C10.4926 16.9458 10.4926 14.9754 11.9704 14.9754C13.4483 14.9754 13.202 16.9458 11.9704 16.9458ZM16.6503 13.1034C15.4187 13.1034 15.4187 11.133 16.6503 11.133C17.8818 11.133 17.8818 13.1034 16.6503 13.1034Z"
                  fill="currentColor"
                />
              </svg>
            </span>

            <p className="text-sm text-gray-600 dark:text-gray-300">
              Nossa site utiliza cookies, para obter mais informações sobre
              isso, leia nossa
              <a
                className="ml-1 text-blue-500 hover:underline"
                href="https://closecar-public.s3.amazonaws.com/Termos+de+Uso+-+Usuario+e+CloseCar.pdf"
                rel="noreferrer"
                target="_blank"
              >
                Política de privacidade.
              </a>
            </p>
          </div>

          <div className="flex items-center mt-6 gap-x-4 shrink-0 lg:mt-0">
            <button
              className=" text-xs w-1/2 md:w-auto font-medium bg-gray-800 rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
              onClick={() => onSaveCookies(false)}
            >
              Aceitar e melhorar minha experiência
            </button>
          </div>
        </section>
      )}
    </div>
  )
}

export default Landpage
