import React from "react"

const FooterRequirements = () => {
  return (
    <div className="container px-6 py-10 mx-auto">
      <h1 className="text-2xl font-semibold text-center text-gray-800 lg:text-3xl">
        Nunca foi tão fácil alugar um carro!
      </h1>

      <h3 className="mt-2 text-base text-center text-gray-800 lg:text-lg">
        Veja alguns do requisitos para iniciar sua locação
      </h3>

      <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
        <div className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl">
          <h1 className="text-xl font-semibold text-gray-700 capitalize">
            CNH
          </h1>

          <p className="text-gray-500">
            Deve possuir a CNH válida e dentro do prazo de vencimento.
          </p>
        </div>

        <div className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl">
          <h1 className="text-xl font-semibold text-gray-700 capitalize">
            Documentos
          </h1>

          <p className="text-gray-500">
            RG e CPF originais e comprovante de endereço. Não ter restrições de
            qualquer espécie junto aos órgãos de proteção ao crédito no momento
            da contratação. Estrangeiros Passaporte original e válido.
          </p>
        </div>

        <div className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl">
          <h1 className="text-xl font-semibold text-gray-700 capitalize">
            Cartão de crédito
          </h1>

          <p className="text-gray-500">
            Cartão de crédito válido, de sua titularidade, dentro do prazo de
            vencimento, emitido por uma instituição bancária e com limite de
            crédito disponível para pré-autorização da caução de garantia.
          </p>
        </div>
      </div>

      <div className="mt-4 text-xs text-center text-gray-800 lg:text-sm">
        * Condições sujeitas à alteração de acordo com a locadora.
      </div>
    </div>
  )
}

export default FooterRequirements
